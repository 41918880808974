import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasModernTheme } from 'common/src/theme/saasModern';
import { ResetCSS } from 'common/src/assets/css/style';
import { ContentWrapper, GlobalStyle } from '../containers/SaasModern/sassModern.style';
import BannerSection from '../containers/SaasModern/Banner';
import Navbar from '../containers/SaasModern/Navbar';
import WorkingProcessSection from '../containers/SaasModern/WorkingProcess';
import FaqSection from '../containers/SaasModern/Faq';
import Footer from '../containers/SaasModern/Footer';
import SEO from '../components/seo';
import PricingSection from '../containers/SaasModern/Pricing';
import { loadStripe } from '@stripe/stripe-js';
import TrialSection from '../containers/SaasModern/Trial';
import TestimonialSection from '../containers/SaasModern/Testimonial';
import { Modal } from '@redq/reuse-modal';
import { AddToCartModal } from '../containers/Saas/LeadSection/LeadModal';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import { nFormatter } from '../utils/Utils';
import './tech.css';
import TechNavBar from '../containers/SaasModern/Navbar/TechNavBar';

global.stripePromise = loadStripe(process.env.STRIPE_KEY);

export default ({ pageContext }) => {
  const { tech, techData } = pageContext;

  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <SEO title={`List of companies that use ${tech}`}
             description={`Get emails of key decision makers at every company that uses ${tech} and start selling 🚀`}/>

        <ResetCSS/>
        <GlobalStyle/>

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <TechNavBar />
            </DrawerProvider>
          </Sticky>
          <BannerSection
            heading={<>
              Find contacts at {nFormatter(techData.count)} companies<br/>
              that uses {tech}
            </>}
            descriptionText={<>
              Get emails of key decision makers at every company that uses <a
              href={techData.website} target="_blank" rel="noreferrer"
              style={{ color: 'white' }}>{tech}</a> and start selling
              🚀 <br/>
              Here's what you get 👇
            </>}
            airtableUrl="https://airtable.com/embed/shrKlIIGH28L0Ljjs"/>
          <WorkingProcessSection/>
          <div className="leadsWrapper" id="leads">
            <Box {...WorkingProcessSection.defaultProps.secTitleWrapper}
                 style={{ marginBottom: '70px' }}>
              <div style={{ height: '5rem' }}/>
              <Text {...WorkingProcessSection.defaultProps.secText} content="BUY LEADS"/>
              <Heading
                {...WorkingProcessSection.defaultProps.secHeading}
                content="Get a CSV in your email in one click"
              />
            </Box>
            <AddToCartModal techKey={tech}/>
          </div>
          <PricingSection/>
          <TestimonialSection/>
          <FaqSection/>
          <TrialSection/>
          <Footer/>
        </ContentWrapper>
        <Modal/>
      </Fragment>
    </ThemeProvider>
  );
};